
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Image from '@tiptap/extension-image'
import Table from '@tiptap/extension-table'
import TableRow from '@tiptap/extension-table-row'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import IFrame from './extension/iframe'
import Link from '@tiptap/extension-link'
import Underline from '@tiptap/extension-underline'
import FontSize from "./extension/font-size"
import TextStyle from '@tiptap/extension-text-style'
import TextAlign from '@tiptap/extension-text-align'
import OrderedList from '@tiptap/extension-ordered-list'
import { Color } from '@tiptap/extension-color'

import UiTextEditorToolbar from './ui-text-editor-toolbar.vue'

@Component({
  components: {
    UiTextEditorToolbar,
    EditorContent
  }
})
export default class UiTextEditor extends Vue {
  @Prop({
    type: String
  }) readonly value!: string

  editor: Editor | null = null

  get html (): string {
    if (!this.editor) {
      return ''
    }

    return this.editor.getHTML()
  }

  mounted (): void {
    this.editor = new Editor({
      extensions: [
        StarterKit.configure({}),
        Underline,
        Image,
        Table,
        TableRow,
        TableHeader,
        TableCell,
        IFrame,
        FontSize,
        TextStyle,
        OrderedList,
        Color,
        TextAlign.configure({
          types: ['heading', 'paragraph']
        }),
        Link.configure({
          HTMLAttributes: {
            target: '_blank',
            rel: null
          }
        })
      ],
      content: 'Здесь можно редактировать текст...'
    })

    this.$nextTick(() => {
      window.addEventListener('scroll', this.handleScroll)
    })
  }

  beforeDestroy (): void {
    if (this.editor) {
      this.editor.destroy()
    }

    window.removeEventListener('scroll', this.handleScroll)
  }

  @Watch('value', { immediate: true })
  setValue (value: string): void {
    this.$nextTick(() => {
      if (this.editor && value !== this.value) {
        this.editor.commands.setContent(value, true)
      }
    })
  }

  @Watch('html')
  emitValue (value: string): void {
    if (value === this.value) {
      return
    }

    this.$emit('input', value)
  }

  handleScroll() {
    if (!this.$refs.content || !this.$refs.toolbar) {
      return
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const toolbar = this.$refs.toolbar.$el as HTMLElement
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const content = this.$refs.content.$el as HTMLElement

    const rect = content.getBoundingClientRect()
    const sticky = rect.top < 0 && rect.bottom > toolbar.offsetHeight

    if (toolbar) {
      toolbar.classList.toggle('sticky', sticky)
    }
  }
}
