import { render, staticRenderFns } from "./ui-text-editor-toolbar.vue?vue&type=template&id=0bcdb58d&scoped=true"
import script from "./ui-text-editor-toolbar.vue?vue&type=script&lang=ts"
export * from "./ui-text-editor-toolbar.vue?vue&type=script&lang=ts"
import style0 from "./ui-text-editor-toolbar.vue?vue&type=style&index=0&id=0bcdb58d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bcdb58d",
  null
  
)

export default component.exports