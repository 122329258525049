
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Editor } from '@tiptap/vue-2'

import { TMethodWithArgs, TTextEditorItem } from '@/app/shared/types'
import config from '@/config'
import { uploadFile } from "@/app/shared/utils/upload-file"
import UiTextEditorColor from './ui-text-editor-color.vue'
import UiTextEditorFontSize from './ui-text-editor-font-size.vue'
import UiTextEditorButton from './ui-text-editor-button.vue'
import {
  ACTION_PARAMS,
  ALIGN_TEXT_PARAMS,
  FORMAT_PARAMS, LIST_PARAMS,
  TABLE_PARAMS,
  TITLE_PARAMS,
  AVAILABLE_IMAGE_EXT,
  VALID_IMAGE_TYPES,
  MAX_IMAGE_SIZE,
  IMAGE_RESPONSE_MESSAGES,
  VIDEO_LINK_MESSAGES,
  MATCH_REGEXP_YOUTUBE_VIDEO,
  MATCH_REGEXP_RUTUBE_VIDEO,
  MATCH_REGEXP_VK_VIDEO,
} from './ui-text-editor-toolbar.constants'

@Component({
  components: {
    UiTextEditorColor,
    UiTextEditorFontSize,
    UiTextEditorButton
  }
})
export default class UiTextEditorToolbar extends Vue {
  @Prop({
    type: Object,
    required: true
  }) readonly editor!: Editor

  otherParams: TTextEditorItem[] = [
    {
      icon: 'mdi-link',
      title: 'Ссылка',
      handler: this.setLink,
      isActiveName: 'link'
    },
    {
      icon: 'mdi-image',
      title: 'Картинка',
      handler: this.setImage,
      loading: false
    },
    {
      icon: 'mdi-youtube',
      title: 'Видео (youtube)',
      handler: this.setVideoLink
    }
  ]

  buttonsList: TTextEditorItem[][] = [
    FORMAT_PARAMS,
    TITLE_PARAMS,
    ALIGN_TEXT_PARAMS,
    LIST_PARAMS,
    TABLE_PARAMS,
    this.otherParams,
    ACTION_PARAMS
  ]

  focusHandler(chainItems: TMethodWithArgs[]): void {
    if (!this.editor) {
      return
    }

    /** @TODO Без any тут не обойтись с внешними библиотеками */
    let chain = this.editor.chain().focus() as any

    for (const { method, args } of chainItems) {
      if (typeof chain[method] !== 'function') {
        continue
      }

      chain = chain[method](...(args || []))
    }

    chain.run()
  }

  setImage(): void {
    uploadFile(AVAILABLE_IMAGE_EXT, this.uploadImage)
  }

  toggleImageLoading(): void {
    const params: TTextEditorItem | undefined = this.otherParams.find(button => button.title === 'Картинка')

    if (params) {
      params.loading = !params.loading
    }
  }

  async uploadImage(event: Event): Promise<void> {
    try {
      this.toggleImageLoading()

      const file = (event.target as HTMLInputElement).files?.[0]

      if (!file) {
        throw new Error(IMAGE_RESPONSE_MESSAGES.EMPTY_FILE_ERROR)
      }

      if (!VALID_IMAGE_TYPES.includes(file.type)) {
        throw new Error(IMAGE_RESPONSE_MESSAGES.EXT_ERROR)
      }

      if (file.size > MAX_IMAGE_SIZE) {
        throw new Error(IMAGE_RESPONSE_MESSAGES.SIZE_ERROR)
      }

      const formData = new FormData()

      formData.append('image', file)

      const response = await fetch(`${config.http.baseUrlApi}/upload`, {
        method: 'POST',
        body: formData
      })

      if (!response.ok) {
        throw new Error(IMAGE_RESPONSE_MESSAGES.UPLOAD_ERROR)
      }

      const result = await response.json()

      const { url } = result.data

      if (!url) {
        throw new Error(IMAGE_RESPONSE_MESSAGES.UPLOAD_ERROR)
      }

      this.focusHandler([{
        method: 'setImage',
        args: [{ src: url }]
      }])

      this.$eventBus.$emit('notify', {
        type: 'success',
        message: IMAGE_RESPONSE_MESSAGES.UPLOAD_SUCCESS
      })
    } catch (error) {
      this.$eventBus.$emit('notify', {
        type: 'error',
        message: error.message
      })
    } finally {
      this.toggleImageLoading()
    }
  }

  setVideoLink(): void {
    const url = window.prompt(VIDEO_LINK_MESSAGES.TEXT)

    if (url) {
      const youtubeMatch = url.match(MATCH_REGEXP_YOUTUBE_VIDEO)
      const rutubeMatch = url.match(MATCH_REGEXP_RUTUBE_VIDEO)
      const vkMatch = url.match(MATCH_REGEXP_VK_VIDEO)

      if (youtubeMatch && youtubeMatch[1]) {
        this.focusHandler([{
          method: 'setIframe',
          args: [{ src: `https://www.youtube.com/embed/${youtubeMatch[1]}` }]
        }])

        return
      }

      if (rutubeMatch && rutubeMatch[1]) {
        this.focusHandler([{
          method: 'setIframe',
          args: [{ src: `https://rutube.ru/play/embed/${rutubeMatch[1]}` }]
        }])

        return
      }


      if (vkMatch && vkMatch.groups) {
        const { oid, id } = vkMatch.groups

        if (!id || !oid) {
          this.$eventBus.$emit('notify', {
            type: 'error',
            message: VIDEO_LINK_MESSAGES.LINK_ERROR
          })

          return
        }

        this.focusHandler([{
          method: 'setIframe',
          args: [{ src: `https://vk.com/video_ext.php?oid=-${oid}&id=${id}` }]
        }])

        return
      }

      this.$eventBus.$emit('notify', {
        type: 'error',
        message: VIDEO_LINK_MESSAGES.LINK_ERROR
      })
    }
  }

  setLink(): void {
    const url = window.prompt('URL')

    if (url === '') {
      this.focusHandler([
        {
          method: 'extendMarkRange',
          args: ['link']
        },
        {
          method: 'unsetLink'
        }
      ])

      return
    }

    if (url) {
      this.focusHandler([
        {
          method: 'extendMarkRange',
          args: ['link']
        },
        {
          method: 'setLink',
          args: [{ href: url }]
        }
      ])
    }
  }
}
