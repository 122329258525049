const toHex = (component: number): string => {
  const hex: string = component.toString(16)

  return hex.length === 1 ? '0' + hex : hex
}

const rgbStringToHex = (rgbString: string): string => {
  const regex = /rgb\((\d+), (\d+), (\d+)\)/
  const result: RegExpExecArray | null = regex.exec(rgbString)

  if (!result) {
    return rgbString
  }

  const r: number = parseInt(result[1], 10)
  const g: number = parseInt(result[2], 10)
  const b: number = parseInt(result[3], 10)

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`
}

export {
  toHex,
  rgbStringToHex
}
