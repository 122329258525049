
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { Editor } from "@tiptap/vue-2"

@Component({})
export default class UiTextEditorFontSize extends Vue {
  @Prop({
    type: Object,
    required: true
  }) readonly editor!: Editor

  fontSizes: string[] = Array.from({ length: 29 }, (_, i) => `${8 + i * 2}px`)
  customFontSize = ''

  setFontSize(size: string): void {
    const newSize: string = !size.includes('px') ? size + 'px' : size

    this.$emit('focus-handler', [{
      method: 'setFontSize',
      args: [newSize]
    }])
  }

  @Watch('editor.state.selection', { immediate: true, deep: true })
  onSelectionChange(): void {
    if (!this.editor) {
      return
    }

    const { from } = this.editor.state.selection
    const selectedNode = this.editor.state.doc.nodeAt(from)

    if (selectedNode && selectedNode.marks) {
      const fontSizeMark = selectedNode.marks.find(mark => mark.attrs.fontSize)

      if (fontSizeMark) {
        this.customFontSize = fontSizeMark.attrs.fontSize
      } else {
        this.customFontSize = ''
      }
    }
  }
}
