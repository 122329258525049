
// lib
import { Vue, Component, Prop } from 'vue-property-decorator'

// app
import ConstructorComponent from '@/app/constructor/shared/models/ConstructorComponent'
import UiFormGroup from '@/app/shared/components/ui-form-group.vue'
import UiTextEditor from '@/app/shared/components/ui-text-editor/index.vue'

@Component({
  components: {
    UiFormGroup,
    UiTextEditor
  }
})
export default class AppArticleBlockText extends Vue {
  @Prop({ type: ConstructorComponent }) readonly value!: ConstructorComponent

  html = ''

  get text (): string {
    return this.value.attrs.text as string
  }
  set text (value: string) {
    this.$emit('input', new ConstructorComponent({
      ...this.value,
      $uuid: this.value.$uuid,
      attrs: {
        title: this.title,
        text: value
      }
    }))
  }

  get title (): string {
    return this.value.attrs.title as string
  }
  set title (value: string) {
    this.$emit('input', new ConstructorComponent({
      ...this.value,
      $uuid: this.value.$uuid,
      attrs: {
        title: value,
        text: this.text
      }
    }))
  }

  // get computedTitleHtml (): [string, string] {
  //   return [this.title, this.html]
  // }

//   makeArticleBlock (): string {
//     return `
// <article-block title="${this.title}">
//   <template #content>
//     ${this.html}
//   </template>
// </article-block>
//     `
//   }

  // @Watch('computedTitleHtml')
  // emitArticleBlock (): void {
  //   this.$emit('input', this.makeArticleBlock())
  // }
}
