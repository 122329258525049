const uploadFile = (acceptExtensions: string, callback: (event: Event) => void): void => {
  const input = document.createElement('input')

  input.type = 'file'
  input.accept = acceptExtensions
  input.style.display = 'none'

  input.addEventListener('change', (event) => callback(event))

  document.body.appendChild(input)

  input.click()

  document.body.removeChild(input)
}

export {
  uploadFile
}
