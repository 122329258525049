
import { Vue, Component, Prop } from 'vue-property-decorator'
import { rgbStringToHex } from "@/app/shared/utils/color"
import { Editor } from "@tiptap/vue-2"

@Component({})
export default class UiTextEditorColor extends Vue {
  @Prop({
    type: Object,
    required: true
  }) readonly editor!: Editor

  get textColor(): string {
    return rgbStringToHex(this.editor.getAttributes('textStyle').color)
  }

  onInput(event: Event): void {
    const target = event.target as HTMLInputElement

    this.$emit('focus-handler', [{
      method: 'setColor',
      args: [target?.value]
    }])
  }
}
