
import { Vue, Component, Prop } from 'vue-property-decorator'
import { TTextEditorItem } from '@/app/shared/types'
import { Editor } from "@tiptap/vue-2"

@Component({})
export default class uiTextEditorButtons extends Vue {
  @Prop({
    type: Object,
    required: true
  }) readonly editor!: Editor

  @Prop({
    type: Object,
    required: true
  }) readonly button!: TTextEditorItem

  isActive(button: TTextEditorItem): boolean {
    return (
      (!!button?.isActiveName || !!button.isActiveArgs) &&
      this.editor.isActive(button.isActiveName || '', button.isActiveArgs)
    )
  }

  onClick(button: TTextEditorItem): void {
    if (button.handler) {
      button.handler()

      return
    }

    if (button.params) {
      this.$emit('focus-handler', button.params)
    }
  }
}
