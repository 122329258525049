import { TTextEditorItem } from '@/app/shared/types'

const TITLE_PARAMS: TTextEditorItem[] = [
  {
    icon: 'mdi-format-header-3',
    title: 'Подзаголовок 3',
    params: [{
      method: 'toggleHeading',
      args: [{ level: 3 }]
    }],
    isActiveName: 'heading',
    isActiveArgs: { level: 3 }
  },
  {
    icon: 'mdi-format-header-4',
    title: 'Подзаголовок 4',
    params: [{
      method: 'toggleHeading',
      args: [{ level: 4 }]
    }],
    isActiveName: 'heading',
    isActiveArgs: { level: 4 }
  }
]

const TABLE_PARAMS: TTextEditorItem[] = [
  {
    icon: 'mdi-table-plus',
    title: 'Добавить таблицу',
    params: [{
      method: 'insertTable',
      args: [{
        rows: 3,
        cols: 3,
        withHeaderRow: true
      }]
    }]
  },
  {
    icon: 'mdi-table-column-plus-after',
    title: 'Добавить колонку',
    params: [{ method: 'addColumnAfter' }]
  },
  {
    icon: 'mdi-table-column-remove',
    title: 'Удалить колонку',
    params: [{ method: 'deleteColumn' }]
  },
  {
    icon: 'mdi-table-row-plus-after',
    title: 'Добавить строку',
    params: [{ method: 'addRowAfter' }]
  },
  {
    icon: 'mdi-table-row-remove',
    title: 'Удалить строку',
    params: [{ method: 'deleteRow' }]
  }
]

const FORMAT_PARAMS: TTextEditorItem[] = [
  {
    icon: 'mdi-format-bold',
    title: 'Жирный',
    params: [{ method: 'toggleBold' }],
    isActiveName: 'bold'
  },
  {
    icon: 'mdi-format-italic',
    title: 'Курсив',
    params: [{ method: 'toggleItalic' }],
    isActiveName: 'italic'
  },
  {
    icon: 'mdi-format-underline',
    title: 'Подчеркнутый',
    params: [{ method: 'toggleUnderline' }],
    isActiveName: 'underline'
  },
  {
    icon: 'mdi-format-strikethrough',
    title: 'Зачеркнутый',
    params: [{ method: 'toggleStrike' }],
    isActiveName: 'strike'
  }
]

const ALIGN_TEXT_PARAMS: TTextEditorItem[] = [
  {
    icon: 'mdi-format-align-left',
    title: 'Слева',
    params: [{
      method: 'setTextAlign',
      args: ['left']
    }],
    isActiveArgs: { textAlign: 'left' }
  },
  {
    icon: 'mdi-format-align-center',
    title: 'Центр',
    params: [{
      method: 'setTextAlign',
      args: ['center']
    }],
    isActiveArgs: { textAlign: 'center' }
  },
  {
    icon: 'mdi-format-align-right',
    title: 'Справа',
    params: [{
      method: 'setTextAlign',
      args: ['right']
    }],
    isActiveArgs: { textAlign: 'right' }
  }
]

const ACTION_PARAMS: TTextEditorItem[] = [
  {
    icon: 'mdi-undo',
    title: 'Отменить',
    params: [{ method: 'undo' }]
  },
  {
    icon: 'mdi-redo',
    title: 'Вернуть',
    params: [{ method: 'redo' }]
  }
]

const LIST_PARAMS: TTextEditorItem[] = [
  {
    icon: 'mdi-format-list-bulleted',
    title: 'Список',
    params: [{ method: 'toggleBulletList' }]
  },
  {
    icon: 'mdi-format-list-numbered',
    title: 'Нумерованый список',
    params: [{ method: 'toggleOrderedList' }]
  }
]

const AVAILABLE_IMAGE_EXT = '.jpg,.jpeg,.png,.tiff'

const VALID_IMAGE_TYPES: string[] = ['image/jpeg', 'image/png', 'image/tiff']

const MAX_IMAGE_SIZE = 5 * 1024 * 1024 // 5MB

const IMAGE_RESPONSE_MESSAGES = {
  EMPTY_FILE_ERROR: 'Не выбран файл для загрузки',
  EXT_ERROR: 'Поддерживаются только форматы JPG, PNG и TIFF',
  SIZE_ERROR: 'Размер файла не должен превышать 5MB',
  UPLOAD_ERROR: 'Ошибка загрузки файла',
  UPLOAD_SUCCESS: 'Файл успешно загружен'
}

const VIDEO_LINK_MESSAGES = {
  TEXT: 'Введите ссылку на видео (YouTube, ВКонтакте, RuTube)',
  LINK_ERROR: 'Неправильная ссылка на видео. Поддерживаемые платформы: YouTube, ВКонтакте, RuTube.'
}

// eslint-disable-next-line
const MATCH_REGEXP_YOUTUBE_VIDEO: RegExp = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
// eslint-disable-next-line
const MATCH_REGEXP_RUTUBE_VIDEO: RegExp = /rutube\.ru\/(?:video|tracks|play\/embed)\/([a-zA-Z0-9-]+)/
// eslint-disable-next-line
const MATCH_REGEXP_VK_VIDEO: RegExp = /video-(?<oid>-?\d+)_(?<id>\d+)/

export {
  TITLE_PARAMS,
  TABLE_PARAMS,
  FORMAT_PARAMS,
  ALIGN_TEXT_PARAMS,
  ACTION_PARAMS,
  LIST_PARAMS,
  AVAILABLE_IMAGE_EXT,
  VALID_IMAGE_TYPES,
  MAX_IMAGE_SIZE,
  IMAGE_RESPONSE_MESSAGES,
  VIDEO_LINK_MESSAGES,
  MATCH_REGEXP_YOUTUBE_VIDEO,
  MATCH_REGEXP_RUTUBE_VIDEO,
  MATCH_REGEXP_VK_VIDEO
}
